/* import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css'; */

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

main{
  background-color: 'rgba(100, 100, 100, 0.5)'
}


.swatches-picker  span div {
  border: 1px solid #f1f1f1;
  
}

/* legacy  */
.board{
  /* height: 80%; */
  /* margin: 20px; */
  /* min-height: 100vh; */
  /* height: 800px; */
  /* width: 1000px; */
  /* width: 100%; */
  /* border: 1px solid #ccc; 
  /* resize: vertical; */
  /* overflow: scroll; */
  /* background: rgba(100, 100, 100, 0.01);
  background-image: radial-gradient(#ccc 1px, transparent 0);
  background-size: 20px 20px;
  background-position: -10px -10px;  */ 
}

/* .chart-container{ */
  /* position: "relative"; */
  /* background-color: white; */
  /* border: 1px solid; */
  /* border-color: ; */
/* } */

/* .chart-container-active{ */
  /* border: 3px solid; */
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  /* box-shadow:   3px 3px 3px rgba(0, 0, 0, 0.24); */
/* } */

.side-menu{
  width: 10vw;
  padding-right: 80px;
  margin-right: 100px; 
  min-height: 100vh;
}

.data-table{
  margin-left: 20px;
  margin-top: 20px;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
}

.table-col{
}

.table-label{
  /* float:left; */
  overflow-x: scroll;
  display: block;
  height: 1.2em;
  max-height: 1.2em;
  width: 5em;
  max-width: 5em;
  padding: 5px;
  border: 1px solid black;
}

.header{
  font-weight: bold;
  /* color:red; */
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}


.hide{
  display: none !important; 
}


.leftSideMenu{
  background-color:rgba(2, 38, 80, 0.376)
}


.chart-data-table-labels-cell{
  background-color: 'red !important';
}